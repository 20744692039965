import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.css'; // Keep the existing CSS module
import { auth } from '../../config/firebase'; // Ensure Firebase is configured
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Typography, Link } from '@mui/material';
function NavigationBar() {
  const [isActive, setIsActive] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 960);
  const navigate = useNavigate();

  // Monitor window size for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Check if the user is signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  // Log out functionality
  const logOut = async () => {
    try {
      await signOut(auth);
      navigate('/auth');
    } catch (error) {
      console.error(error);
    }
  };

  // Toggle hamburger menu
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  // Close the menu on item click
  const removeActive = () => {
    setIsActive(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className={`${styles.navbar}`}>
          {/* Logo */}
          <img src="/logo_trans2.png" alt="Logo" className={styles.logo} />
          {/* <a href='/home' style={{ fontSize: '24px', fontWeight: 'bold', textDecoration: 'none', color: '#000' }}  onClick={() => navigate('/')}>PahadiLingo</a> */}
          <span 
          className={styles.navlinkhome} 
          onClick={() => navigate('/home')}
        >
          PahadiLingo
        </span>
          {/* Navigation Links */}
          <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
            <li onClick={removeActive}>
              <a href='home' className={`${styles.navLink}`} onClick={() => navigate('/')}>Home</a>
            </li>
            <li onClick={removeActive}>
              <a href='quiz' className={`${styles.navLink}`} onClick={() => navigate('/quiz')}>Quiz</a>
            </li>
            <li onClick={removeActive}>
              <a href='grammar' className={`${styles.navLink}`} onClick={() => navigate('/grammar')}>Grammar</a>
            </li>
            <li onClick={removeActive}>
              <a href='dictionary' className={`${styles.navLink}`} onClick={() => navigate('/dictionary')}>Dictionary</a>
            </li>
            <li onClick={removeActive}>
              <a href='about' className={`${styles.navLink}`} onClick={() => navigate('/about')}>About</a>
            </li>
            {/* <li onClick={removeActive}>
              <a href='savedwords' className={`${styles.navLink}`} onClick={() => navigate('/savedwords')}>Favourit words</a>
            </li> */}
          </ul>

          {/* Authentication: Log In / Log Out */}
          <div className={styles.authContainer}>
            <span
              className={styles.navlinkhome}
              onClick={isSignedIn ? logOut : () => navigate('/auth')}
            >
              {isSignedIn ? 'Log Out' : 'Sign In'}
            </span>
          </div>

          {/* Hamburger Icon for Mobile */}
          <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`} onClick={toggleActiveClass}>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
          </div>
        </nav>

        {/* Mobile View Authentication and Navigation Links */}
        {isMobile && isActive && (
          <ul className={styles.mobileMenu}>
            <li onClick={removeActive}>
              <a href='home' className={`${styles.navLink}`} onClick={() => navigate('/')}>Home</a>
            </li>
            <li onClick={removeActive}>
              <a href='grammar' className={`${styles.navLink}`} onClick={() => navigate('/grammar')}>Catalog</a>
            </li>
            <li onClick={removeActive}>
              <a href='dictionary' className={`${styles.navLink}`} onClick={() => navigate('/dictionary')}>All Products</a>
            </li>
            <li onClick={removeActive}>
              <a href='about' className={`${styles.navLink}`} onClick={() => navigate('/about')}>About</a>
            </li>
            <li onClick={removeActive}>
              <a
                href="#"
                className={styles.authLink}
                onClick={isSignedIn ? logOut : () => navigate('/auth')}
              >
                {isSignedIn ? 'Log Out' : 'Sign In'}
              </a>
            </li>
          </ul>
        )}
      </header>
    </div>
  );
}

export default NavigationBar;
