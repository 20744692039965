import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com'; 
import './About.css'; // Import your CSS file for styles

export const About = () => {
  const [from_name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [from_email, setEmail] = useState('');

  useEffect(() => {
    emailjs.init('jScY0PatqyMZlWZkg'); // Initialize EmailJS with your user ID
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name,
      message,
      from_email,
    };

    emailjs.send('service_dbugm7j', 'template_0elmihe', templateParams)
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        alert('Email sent successfully!');
        
        // Clear the form fields
        setName('');
        setMessage('');
        setEmail('');
      })
      .catch((err) => {
        console.error('Failed to send email. Error: ', err);
        alert('Failed to send email. Please try again.');
      });
  };

  return (
    <div className="about-container">
      <div className="introduction">
        <h4>Embrace the Himalayan Tongues</h4>
        <h7>Connect with Pahadi culture, one word at a time.</h7>
        <p>
          Pahadi regions of India have a range of cultural languages, each with its unique vocabulary, grammar, syntax, and pronunciation. 
          Unfortunately, there has been a growing negligence towards these languages, both by the local communities, by state and central government. 
          These languages are native to the region, and are vital to the region’s heritage, having been spoken long before the arrival of language they are being labeled as "dialects."
           It is essential to respect and preserve the languages that our ancestors nurtured and passed down through generations. 
           To address this challenge, this website is built to promote the language and culture of Uttarakhand.
        </p>
        {/* <br/> */}
        <p>
        This site’s linguist content is meticulously being compiled by one of the most dedicated individual of Team GarhKumaon, whose knowledge spans various dialects of the Central Pahadi languages. With unwavering passion, working tirelessly to build a community that cultivates the rich linguistic heritage of our beautiful tongues of Uttarakhand.
        <br/>To contact him visit his Instagram page
          <a className="contact-link" href="https://www.instagram.com/garhkumaon_linguist" target="_blank" rel="noopener noreferrer"> @garhkumaon_linguist</a>
        </p>
      </div>

      <div className="contact-section">
        <h4>Contact Us</h4>
        <h7>
          If you want to contribute to this initiative, contact me at 
          <a className="contact-link" href="mailto:kunwarisha9@gmail.com"> kunwarisha9@gmail.com</a>.
        </h7>
        <p>We would love to hear from you! Please send us your reviews or messages.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Your Name"
            value={from_name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            value={from_email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <textarea
            placeholder="Your Message"
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};
