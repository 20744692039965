import React, { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { auth } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import './Quiz.css';

const Quiz = () => {
  const [words, setWords] = useState([]);
  const [language, setLanguage] = useState('Kumauni');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0); // Current quiz score
  const [showResults, setShowResults] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [totalScore, setTotalScore] = useState(0); // Cumulative score from Firebase
  const languages = ['Garhwali', 'Jaunsari', 'Kumauni'];
  const navigate = useNavigate();


  const fetchWords = useCallback(async () => {
    const db = getDatabase();
    const wordsRef = ref(db, 'words');
    const snapshot = await get(wordsRef);

    if (snapshot.exists()) {
      const wordData = snapshot.val();
      const wordList = Object.entries(wordData).map(([word, value]) => ({
        word,
        correctAnswer: value[language],
      }));

      const shuffledWords = wordList.sort(() => 0.5 - Math.random()).slice(0, 10);
      setWords(shuffledWords);
    }
  }, [language]);

  useEffect(() => {
    if (language) fetchWords();
    if (auth.currentUser) fetchUserScore();
  }, [language, fetchWords]);

  const fetchUserScore = async () => {
    const user = auth.currentUser;
    if (user) {
      const db = getDatabase();
      const scoreRef = ref(db, `user/${user.uid}/score`);
      const snapshot = await get(scoreRef);
      if (snapshot.exists()) {
        setTotalScore(snapshot.val());
      }
    }
  };

  const shuffleOptions = (currentWord, wordList) => {
    const otherWords = wordList.filter(w => w.word !== currentWord.word);
    const randomOptions = otherWords
      .sort(() => 0.5 - Math.random())
      .slice(0, 3)
      .map(w => w.correctAnswer);

    const options = [...randomOptions, currentWord.correctAnswer];
    return options.sort(() => 0.5 - Math.random());
  };

  const handleAnswer = (selectedAnswer) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[currentQuestion] = selectedAnswer;
    setSelectedAnswers(newSelectedAnswers);

    if (selectedAnswer === words[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < words.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResults(true);
      if (auth.currentUser) updateUserScore(); // Update score in Firebase
    }
  };

  const updateUserScore = async () => {
  const user = auth.currentUser;
  if (user) {
    const db = getDatabase();
    const scoreRef = ref(db, `user/${user.uid}/score`);
    
    // Calculate new total score
    const updatedTotalScore = totalScore + score;

    try {
      // Update total score in Firebase
      await set(scoreRef, updatedTotalScore);

      // Update state to reflect the new total score
      setTotalScore(updatedTotalScore);
    } catch (error) {
      console.error('Error updating score:', error);
    }
  }
};

  
  const handleLanguageSelect = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <div className="quiz-container">
      <h2 className="quiz-title">Guess the Word in {language}</h2>
      <select className="language-select" onChange={handleLanguageSelect} value={language}>
        <option value="">Select Language</option>
        {languages.map((lang) => (
          <option key={lang} value={lang}>
            {lang}
          </option>
        ))}
      </select>

      {!showResults ? (
        words.length > 0 ? (
          <div className="question-container">
            <h3 className="question-word">{words[currentQuestion]?.word}</h3>
            <div className="options-container">
              {shuffleOptions(words[currentQuestion], words).map((option, index) => (
                <button className="option-button" key={index} onClick={() => handleAnswer(option)}>
                  {option}
                </button>
              ))}
            </div>
            <p className="question-progress">Question {currentQuestion + 1} of {words.length}</p>
          </div>
        ) : (
          <p>Loading questions...</p>
        )
      ) : (
<div className="results-container">
  <h2>Your Score for This Quiz: {score}/{words.length}</h2>
  {/* <h3>Your Total Score: {totalScore}</h3> */}

  <h3>Review Your Answers:</h3>
  <ul className="answers-review">
    {words.map((word, index) => (
      <li key={index}>
        <strong>Word:</strong> {word.word}<br />
        <strong>Your Answer:</strong> {selectedAnswers[index]}<br />
        {selectedAnswers[index] !== word.correctAnswer && (
          <>
            <span style={{ color: 'red' }}> (Incorrect)</span>
            <strong>Correct Answer:</strong> {word.correctAnswer}
          </>
        )}
      </li>
    ))}
  </ul>

  <button className="home-button" onClick={() => navigate('/')}>Return Home</button>
</div>


      )}
    </div>
  );
};

export default Quiz;
