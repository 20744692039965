import React from 'react';
import { Box, Typography, CircularProgress, Grid } from '@mui/material';

export const Grammar = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundColor: '#f5f5f5' }} // Optional background color
    >
      <Grid container justifyContent="center" alignItems="center" direction="column">
        {/* <CircularProgress sx={{ mb: 3 }} /> Spinner to show loading effect */}
        <Typography variant="h4" gutterBottom>
          Coming Soon!
        </Typography>
        <Typography variant="body1">
          We are working on this feature. Stay tuned!
        </Typography>
      </Grid>
    </Box>
  );
};
