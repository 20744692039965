import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import { useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, get, set, remove } from 'firebase/database';
import { Typography, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { LiveSearch } from './Home/LiveSearch';
import { Snackbar, Alert } from '@mui/material';
import { Helmet } from 'react-helmet';

export const Home = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); 
  const [favorites, setFavorites] = useState({});
  // const [currentPage, setCurrentPage] = useState(1);
  // const [resultsPerPage] = useState(5);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const globalStyles = {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700, // ExtraBold
    fontSize: '20px',
  };
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const fetchSearchResults = useCallback(async () => {
    if (searchQuery.trim() !== '') {
      try {
        const database = getDatabase();
        const wordsRef = ref(database, 'words');
        const snapshot = await get(wordsRef);

        if (snapshot.exists()) {
          const wordData = snapshot.val();
          const translations = Object.entries(wordData)
            .filter(([word]) => word.toLowerCase().includes(searchQuery.toLowerCase()))
            .map(([word, value]) => ({
              word,
              Garhwali: value.Garhwali || '',
              Jaunsari: value.Jaunsari || '',
              Kumauni: value.Kumauni || '',
            }));
          setSearchResults(translations);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      try {
        const database = getDatabase();
        const wordsRef = ref(database, 'words');
        const snapshot = await get(wordsRef);

        if (snapshot.exists()) {
          const wordData = snapshot.val();
          const allResults = Object.entries(wordData).map(([word, value]) => ({
            word,
            Garhwali: value.Garhwali || '',
            Jaunsari: value.Jaunsari || '',
            Kumauni: value.Kumauni || '',
          }));
          setSearchResults(allResults);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }, [searchQuery, setSearchResults]);

  // Fetch user's favorites from the database
  const fetchFavorites = useCallback(async () => {
    if (isSignedIn) {
      const user = auth.currentUser;
      const database = getDatabase();
      const favoriteRef = ref(database, `user_favorites/${user.uid}`);
      try {
        const snapshot = await get(favoriteRef);
        if (snapshot.exists()) {
          setFavorites(snapshot.val());
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    }
  }, [isSignedIn]);

// Add or remove word from favorites
  const handleFavoriteClick = async (word, Garhwali, Jaunsari, Kumauni) => {
    if (isSignedIn) {
      const user = auth.currentUser;
      const database = getDatabase();
      const favoriteRef = ref(database, `user_favorites/${user.uid}/${word}`);
      try {
        const snapshot = await get(favoriteRef);
        if (snapshot.exists()) {
          // Remove from favorites if already favorited
          setSnackbarMessage(`removing word from favorites`);
          setSnackbarSeverity('success');
          await remove(favoriteRef);
          setFavorites((prevFavorites) => {
            const newFavorites = { ...prevFavorites };
            delete newFavorites[word];
            return newFavorites;
          });
                
        } else {
          // Add to favorites if not already favorited
           // Show success message for addition
        setSnackbarMessage(`word added to favorites`);
        setSnackbarSeverity('success');
          // await set(favoriteRef, { word });
          await set(favoriteRef, {
            word,
            garhwaliTranslation: Garhwali || "", // Save the Garhwali translation if it exists
            jaunsariTranslation: Jaunsari || "", // Save the Jaunsari translation if it exists
            kumauniTranslation: Kumauni || "", // Save the Kumauni translation if it exists
          });
          setFavorites((prevFavorites) => ({
            ...prevFavorites,
            [word]: true,
          }));
        }
       

        // fetchSearchResults(); // Refresh the search results after adding/removing from favorites
      } catch (error) {
        // Show error message
      setSnackbarMessage('Error updating favorite status');
      setSnackbarSeverity('error');
      console.error('Error updating favorite status:', error);
      }
    } else {
      // Show error message if user is not signed in
    setSnackbarMessage('User must be logged in to save favorites');
    setSnackbarSeverity('error');
      // alert('User must be logged in to save favorites');
    }
    setOpenSnackbar(true); 
  };
  useEffect(() => {
    fetchFavorites();
  }, [isSignedIn, fetchFavorites]);

  useEffect(() => {
    const fetchData = async () => {
      if (searchQuery && searchQuery.trim() !== '') {
        await fetchSearchResults();
      } else {
        setSearchResults([]);
      }
    };

    fetchData();
  }, [searchQuery, fetchSearchResults]);

  // const logOut = async () => {
  //   try {
  //     await signOut(auth);
  //     navigate('/auth');
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const toggleMode = () => {
  //   if (isSignedIn) {
  //     logOut();
  //   } else {
  //     navigate('/auth');
  //   }
  // };

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
    <Helmet>
      <title>PahadiLingo - Home</title>
      <meta name="description" content="Explore and learn Pahadi languages like Garhwali and Kumauni with our dictionary" />
      <meta name="keywords" content="Pahadi languages, Garhwali, Kumauni, Dictionary, PahadiLingo, Kumaouni" />
      <meta name="robots" content="index, follow" />
      v
    </Helmet>

    <Typography component="h1" variant="h5"  
      className="pt-20 pl-20 text-sm" sx={{ ...globalStyles, color: '#1E3E62' }}>
      PahadiLingo
    </Typography>
    <br/>

      <LiveSearch
        results={searchResults}
        renderItem={(result) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 0' }}>
            <div>
              <strong>Word:</strong> {result.word}
              <br />
              <strong>Garhwali:</strong> {result.Garhwali}
              <br />
              <strong>Jaunsari:</strong> {result.Jaunsari}
              <br />
              <strong>Kumauni:</strong> {result.Kumauni}
            </div>
            <IconButton 
              aria-label="add to favorites"
              onClick={() => handleFavoriteClick(result.word, result.Garhwali, result.Jaunsari, result.Kumauni)} 
              color={favorites[result.word] ? 'error' : 'default'}
            >
              <FavoriteIcon />
            </IconButton>
          </div>
        )}
        
        
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onSelect={(selectedItem) => {
          console.log(selectedItem);
        }}
      />
          {/* Snackbar for alerts */}
          <Snackbar
  open={openSnackbar}
  autoHideDuration={3000}
  onClose={() => setOpenSnackbar(false)}
>
  <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
    {snackbarMessage}
  </Alert>
</Snackbar>

    </>
  );
};