import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import { getDatabase, ref, get, set, remove, update} from 'firebase/database';
import { Typography, IconButton, TextField, Button, Box, Grid } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
// import { LiveSearch } from './Home/LiveSearch';
import { LiveSearch } from '../components/Home/LiveSearch';
import { Snackbar, Alert } from '@mui/material';
import { Helmet } from 'react-helmet';

export const AdminHome = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  // const [selectedItem, setSelectedItem] = useState(null); 
  const [favorites, setFavorites] = useState({});
  // const [currentPage, setCurrentPage] = useState(1);
  // const [resultsPerPage] = useState(5);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const [newWord, setNewWord] = useState('');
  const [garhwaliTranslation, setGarhwaliTranslation] = useState('');
  const [jaunsariTranslation, setJaunsariTranslation] = useState('');
  const [kumauniTranslation, setKumauniTranslation] = useState('');
  const [originalword,setoriginalword]= useState('');
  const [newupdateWord, setUpdateNewWord] = useState('');
  const [overwritegarhwaliTranslation, setOverwriteGarhwaliTranslation] = useState('');
  const [overwritejaunsariTranslation, setOverwriteJaunsariTranslation] = useState('');
  const [overwritekumauniTranslation, setOverwriteKumauniTranslation] = useState('');
  const [wordtodelete, setwordtodelete] = useState('');

  const globalStyles = {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700, // ExtraBold
    fontSize: '20px',
  };
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  const fetchSearchResults = useCallback(async () => {
    if (searchQuery.trim() !== '') {
      try {
        const database = getDatabase();
        const wordsRef = ref(database, 'words');
        const snapshot = await get(wordsRef);

        if (snapshot.exists()) {
          const wordData = snapshot.val();
          const translations = Object.entries(wordData)
            .filter(([word]) => word.toLowerCase().includes(searchQuery.toLowerCase()))
            .map(([word, value]) => ({
              word,
              Garhwali: value.Garhwali || '',
              Jaunsari: value.Jaunsari || '',
              Kumauni: value.Kumauni || '',
            }));
          setSearchResults(translations);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      try {
        const database = getDatabase();
        const wordsRef = ref(database, 'words');
        const snapshot = await get(wordsRef);

        if (snapshot.exists()) {
          const wordData = snapshot.val();
          const allResults = Object.entries(wordData).map(([word, value]) => ({
            word,
            Garhwali: value.Garhwali || '',
            Jaunsari: value.Jaunsari || '',
            Kumauni: value.Kumauni || '',
          }));
          setSearchResults(allResults);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }, [searchQuery, setSearchResults]);

  const handleAddWord = async () => {
    if (!newWord.trim()) {
      setSnackbarMessage('Please enter a word');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }
  
    const formattedWord =
      newWord.charAt(0).toUpperCase() + newWord.slice(1).toLowerCase();
    const database = getDatabase();
    const wordRef = ref(database, `words/${formattedWord}`);
  
    try {
      const snapshot = await get(wordRef);
      const existingData = snapshot.exists() ? snapshot.val() : null;
  
      if (existingData) {
        // Append translations only if the respective fields are filled
        const updatedTranslations = {
          Garhwali: garhwaliTranslation.trim()
            ? existingData.Garhwali
              ? existingData.Garhwali + `, ${garhwaliTranslation.trim()}`
              : garhwaliTranslation.trim()
            : existingData.Garhwali, // Keep existing translation if new is empty
          Jaunsari: jaunsariTranslation.trim()
            ? existingData.Jaunsari
              ? existingData.Jaunsari + `, ${jaunsariTranslation.trim()}`
              : jaunsariTranslation.trim()
            : existingData.Jaunsari,
          Kumauni: kumauniTranslation.trim()
            ? existingData.Kumauni
              ? existingData.Kumauni + `, ${kumauniTranslation.trim()}`
              : kumauniTranslation.trim()
            : existingData.Kumauni,
        };
  
        // Update the existing word with appended translations
        await update(wordRef, updatedTranslations);
        setSnackbarMessage('Translations updated successfully!');
      } else {
        // Create a new word entry
        await set(wordRef, {
          Garhwali: garhwaliTranslation.trim(),
          Jaunsari: jaunsariTranslation.trim(),
          Kumauni: kumauniTranslation.trim(),
        });
        setSnackbarMessage('Word added successfully!');
      }
  
      setSnackbarSeverity('success');
      setNewWord('');
      setGarhwaliTranslation('');
      setJaunsariTranslation('');
      setKumauniTranslation('');
      fetchSearchResults();
    } catch (error) {
      console.error('Error adding word:', error);
      // setSnackbarMessage('Error adding word!');
      // setSnackbarSeverity('error');
      setSnackbarMessage('Error, try Login with Admin');
      setSnackbarSeverity('error');
    }
  
    setOpenSnackbar(true);
  };
  

const handleOverwriteWord = async () => {
  if (!originalword.trim()) {
    setSnackbarMessage('Please provide the original word');
    setSnackbarSeverity('error');
    setOpenSnackbar(true);
    return;
  }

  const database = getDatabase();
  const formattedOriginalWord =
    originalword.charAt(0).toUpperCase() + originalword.slice(1).toLowerCase();
  const formattedNewWord =
    newupdateWord?.trim()
      ? newupdateWord.charAt(0).toUpperCase() + newupdateWord.slice(1).toLowerCase()
      : null;

  const originalWordRef = ref(database, `words/${formattedOriginalWord}`);
  const newWordRef = formattedNewWord ? ref(database, `words/${formattedNewWord}`) : null;

  try {
    const originalSnapshot = await get(originalWordRef);

    if (!originalSnapshot.exists()) {
      setSnackbarMessage('Original word does not exist');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    // Check if new word already exists in the database
    if (formattedNewWord) {
      const newWordSnapshot = await get(newWordRef);
      if (newWordSnapshot.exists()) {
        setSnackbarMessage('The new word already exists in the database. Please choose a unique word.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
    }

    // Get the original word's data
    const originalData = originalSnapshot.val();

    // Build updated data
    const updatedData = {
      Garhwali: overwritegarhwaliTranslation?.trim() || originalData.Garhwali || '',
      Jaunsari: overwritejaunsariTranslation?.trim() || originalData.Jaunsari || '',
      Kumauni: overwritekumauniTranslation?.trim() || originalData.Kumauni || '',
    };

    if (formattedNewWord) {
      // If a new word is specified, add it to the database
      await set(newWordRef, updatedData);

      // Remove the original word
      await remove(originalWordRef);

      setSnackbarMessage('Word successfully overwritten to a new word!');
    } else {
      // Update the original word with new translations
      await set(originalWordRef, updatedData);

      setSnackbarMessage('Word updated successfully!');
    }

    setSnackbarSeverity('success');
    setoriginalword('');
    setUpdateNewWord('');
    setOverwriteGarhwaliTranslation('');
    setOverwriteJaunsariTranslation('');
    setOverwriteKumauniTranslation('');
    fetchSearchResults();
  } catch (error) {
    console.error('Error overwriting word:', error);
    setSnackbarMessage('Error overwriting word!, try Login with Admin');
    setSnackbarSeverity('error');
  }

  setOpenSnackbar(true);
};

const handleDeleteWord = async () => {
  if (!wordtodelete.trim()) { // Use wordtodelete instead of originalword here
    setSnackbarMessage('Please provide a word to delete');
    setSnackbarSeverity('error');
    setOpenSnackbar(true);
    return;
  }

  const database = getDatabase();
  const formattedWord =
    wordtodelete.charAt(0).toUpperCase() + wordtodelete.slice(1).toLowerCase();
  const wordRef = ref(database, `words/${formattedWord}`);

  try {
    const snapshot = await get(wordRef);
    if (snapshot.exists()) {
      await remove(wordRef);
      setSnackbarMessage('Word deleted successfully!');
      setSnackbarSeverity('success');
      setwordtodelete(''); // Clear the input after deletion
      fetchSearchResults(); // Refresh the results
    } else {
      setSnackbarMessage('Word not found in the database');
      setSnackbarSeverity('error');
    }
  } catch (error) {
    console.error('Error deleting word:', error);
    setSnackbarMessage('Error deleting word!, try Login with Admin');
    setSnackbarSeverity('error');
  }

  setOpenSnackbar(true);
};




  // Fetch user's favorites from the database
  const fetchFavorites = useCallback(async () => {
    if (isSignedIn) {
      const user = auth.currentUser;
      const database = getDatabase();
      const favoriteRef = ref(database, `user_favorites/${user.uid}`);
      try {
        const snapshot = await get(favoriteRef);
        if (snapshot.exists()) {
          setFavorites(snapshot.val());
        }
      } catch (error) {
        console.error('Error fetching favorites:', error);
      }
    }
  }, [isSignedIn]);

// Add or remove word from favorites
  const handleFavoriteClick = async (word, Garhwali, Jaunsari, Kumauni) => {
    if (isSignedIn) {
      const user = auth.currentUser;
      const database = getDatabase();
      const favoriteRef = ref(database, `user_favorites/${user.uid}/${word}`);
      try {
        const snapshot = await get(favoriteRef);
        if (snapshot.exists()) {
          // Remove from favorites if already favorited
          setSnackbarMessage(`removing word from favorites`);
          setSnackbarSeverity('success');
          await remove(favoriteRef);
          setFavorites((prevFavorites) => {
            const newFavorites = { ...prevFavorites };
            delete newFavorites[word];
            return newFavorites;
          });
                
        } else {
          // Add to favorites if not already favorited
           // Show success message for addition
        setSnackbarMessage(`word added to favorites`);
        setSnackbarSeverity('success');
          // await set(favoriteRef, { word });
          await set(favoriteRef, {
            word,
            garhwaliTranslation: Garhwali || "", // Save the Garhwali translation if it exists
            jaunsariTranslation: Jaunsari || "", // Save the Jaunsari translation if it exists
            kumauniTranslation: Kumauni || "", // Save the Kumauni translation if it exists
          });
          setFavorites((prevFavorites) => ({
            ...prevFavorites,
            [word]: true,
          }));
        }
       

        // fetchSearchResults(); // Refresh the search results after adding/removing from favorites
      } catch (error) {
        // Show error message
      setSnackbarMessage('Error updating favorite status');
      setSnackbarSeverity('error');
      console.error('Error updating favorite status:', error);
      }
    } else {
      // Show error message if user is not signed in
    setSnackbarMessage('User must be logged in to save favorites');
    setSnackbarSeverity('error');
      // alert('User must be logged in to save favorites');
    }
    setOpenSnackbar(true); 
  };
  useEffect(() => {
    fetchFavorites();
  }, [isSignedIn, fetchFavorites]);

  useEffect(() => {
    const fetchData = async () => {
      if (searchQuery && searchQuery.trim() !== '') {
        await fetchSearchResults();
      } else {
        setSearchResults([]);
      }
    };

    fetchData();
  }, [searchQuery, fetchSearchResults]);

   return (
    <>
    <Helmet>
      <title>PahadiLingo - Home</title>
      <meta name="description" content="Explore and learn Pahadi languages like Garhwali and Kumauni with our dictionary" />
      <meta name="keywords" content="Pahadi languages, Garhwali, Kumauni, Dictionary, PahadiLingo, Kumaouni" />
      <meta name="robots" content="index, follow" />
      v
    </Helmet>

    <Typography component="h1" variant="h5"  
      className="pt-20 pl-20 text-sm" sx={{ ...globalStyles, color: '#1E3E62' }}>
      PahadiLingo
    </Typography>
    <br/>
    <Box display="flex" gap={3}
    sx={{
       paddingBottom: '100px',
    }}
    >
      <LiveSearch
        results={searchResults}
        renderItem={(result) => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 0' }}>
            <div>
              <strong>Word:</strong> {result.word}
              <br />
              <strong>Garhwali:</strong> {result.Garhwali}
              <br />
              <strong>Jaunsari:</strong> {result.Jaunsari}
              <br />
              <strong>Kumauni:</strong> {result.Kumauni}
            </div>
            <IconButton 
              aria-label="add to favorites"
              onClick={() => handleFavoriteClick(result.word, result.Garhwali, result.Jaunsari, result.Kumauni)} 
              color={favorites[result.word] ? 'error' : 'default'}
            >
              <FavoriteIcon />
            </IconButton>
          </div>
        )} 
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onSelect={(selectedItem) => {
          console.log(selectedItem);
        }}
      />
    </Box>

    <Box display="flex" gap={6}
  sx={{
        display: 'flex',
        justifyContent: 'center', // Horizontally center
        alignItems: 'center', // Vertically center
        backgroundColor: '#f5f5f5', // background color
    }}>

    <Typography component="h1" variant="h5"  
    className="pt-20 text-sm" sx={{ ...globalStyles, color: '#1E3E62', padding: 2}}>
    Add / Update/ Overwrite / Delete Word
  </Typography>
  </Box>


    <Box display="flex" gap={6}
       sx={{
        display: 'flex',
        justifyContent: 'center', // Horizontally center
        alignItems: 'center', // Vertically center
        backgroundColor: '#f5f5f5', // background color
        padding: 2,
         // paddingBottom: '300px'
    }}>
    

    <Grid
      container
      spacing={4}
      sx={{
        width: '100%',
        maxWidth: 1200, // Optional: limit the maximum width
        margin: '0 auto', // Center align the content
      }}
      >
      {/* Add Word Form */}       
      <Grid item xs={12} md={6}>

      <Typography component="h1" variant="h5"  
        className="pt-20 text-sm" sx={{ ...globalStyles, color: '#1E3E62', padding: 2,  }}>
       Add / Update Word
      </Typography>

        <Box sx={{ 
          width: 300,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: '#fff', // Optional: background color for the form
          boxShadow: 3, // Optional: shadow for better appearance
          borderRadius: 2, // Optional: rounded corners
        }}
          >
          <TextField
            label="Word"
            value={newWord}
            onChange={(e) => setNewWord(e.target.value)}
            fullWidth
          />
          <TextField
            label="Garhwali Translation"
            value={garhwaliTranslation}
            onChange={(e) => setGarhwaliTranslation(e.target.value)}
            fullWidth
          />
          <TextField
            label="Jaunsari Translation"
            value={jaunsariTranslation}
            onChange={(e) => setJaunsariTranslation(e.target.value)}
            fullWidth
          />
          <TextField
            label="Kumauni Translation"
            value={kumauniTranslation}
            onChange={(e) => setKumauniTranslation(e.target.value)}
            fullWidth
          />
          <Button variant="contained" sx={{ backgroundColor: '#0f2c44', '&:hover': { backgroundColor: '#1E3E62' } }} onClick={handleAddWord}>
            Add Word
          </Button>
        </Box>
      </Grid>
      {/* Overwrite Word Form */}
  
      <Grid item xs={12} md={6}>
      <Typography component="h1" variant="h5"  
        className="pt-20  text-sm" sx={{ ...globalStyles, color: '#1E3E62', padding: 2, }}>
      Overwrite Word
      </Typography>
          <Box sx={{ 
          // width: 300,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: '#fff', // Optional: background color for the form
          boxShadow: 3, // Optional: shadow for better appearance
          borderRadius: 2, // Optional: rounded corners
          marginRight: 4,
        }}
      >
          <TextField
            label="Word"
            value={originalword}
            onChange={(e) => setoriginalword(e.target.value)}
            fullWidth
          />
          <TextField
            label="Update Word"
            value={newupdateWord}
            onChange={(e) => setUpdateNewWord(e.target.value)}
            fullWidth
          />
          <TextField
            label="Overwrite Garhwali Translation"
            value={overwritegarhwaliTranslation}
            onChange={(e) => setOverwriteGarhwaliTranslation(e.target.value)}
            fullWidth
          />
          {/* if the user want to update the word let say i have a word 'to buy' and i need to chnage it to 'buy'   */}
          <TextField
            label="Overwrite Jaunsari Translation"
            value={overwritejaunsariTranslation}
            onChange={(e) => setOverwriteJaunsariTranslation(e.target.value)}
            fullWidth
          />
          <TextField
            label="Overwrite Kumauni Translation"
            value={overwritekumauniTranslation}
            onChange={(e) => setOverwriteKumauniTranslation(e.target.value)}
            fullWidth
          />
          <Button variant="contained" sx={{ backgroundColor: '#0f2c44', '&:hover': { backgroundColor: '#1E3E62' } }} onClick={handleOverwriteWord}>
          Overwrite Word
          </Button>

          </Box>
          </Grid>

          <Grid item xs={12} md={6}>
      <Typography component="h1" variant="h5"  
        className="pt-20  text-sm" sx={{ ...globalStyles, color: '#1E3E62', padding: 2, }}>
      Delete Word
      </Typography>
          <Box sx={{ 
          // width: 300,
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: '#fff', // Optional: background color for the form
          boxShadow: 3, // Optional: shadow for better appearance
          borderRadius: 2, // Optional: rounded corners
          marginRight: 4,
        }}
      >
          <TextField
           label="Word to Delete"
            value={wordtodelete} // Using originalword for deletion
            onChange={(e) => setwordtodelete(e.target.value)}
            fullWidth
          />      
         
          <Button variant="contained" sx={{ backgroundColor: '#0f2c44', '&:hover': { backgroundColor: '#1E3E62' } }} onClick={handleDeleteWord}>
          Delete Word
          </Button>

          </Box>
          </Grid>

      
    </Grid>
    
        </Box>
          {/* Snackbar for alerts */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>

    </>
  );
};