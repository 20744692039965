import React, { useState, useEffect } from 'react';
import '../index.css';
import { useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { auth, googleAuth } from '../config/firebase';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export const Auth = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [signInError, setSignInError] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    setEmailError('');
    setPasswordError('');
    setSignInError('');
  }, [email, password, isSignUp]);

  const handleAuth = async () => {
    try {
      if (isSignUp) {
        // Validate password confirmation
        if (password !== confirmPassword) {
          setPasswordError('Passwords do not match');
          return;
        }

        // Check email availability
        const methods = await fetchSignInMethodsForEmail(auth, email);
        if (methods.length > 0) {
          setEmailError('Email is already in use');
          return;
        }

        // Sign up the user
        await createUserWithEmailAndPassword(auth, email, password);
        // Redirect to home page after authentication
        navigate('/home');
        
      } else {
        // Sign in the user
        await signInWithEmailAndPassword(auth, email, password);
           // Check for admin credentials
           if (email === process.env.REACT_APP_ADMIN_USERNAME && password === process.env.REACT_APP_ADMIN_PASSWORD) {
            navigate('/admin');
        }
         else {
            navigate('/home');  
          }
       
      }

      
    } catch (error) {
      console.error(error);
      if (error.code === 'auth/wrong-password') {
        setSignInError('Password incorrect');
      } else if (error.code === 'auth/user-not-found' || error.code === 'auth/invalid-email') {
        setEmailError('Invalid email or account not found');
      } else {
        setSignInError('Authentication failed. Please try again.');
      }
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuth);
      navigate('/home');
    } catch (err) {
      console.error(err);
    }
  };

  const toggleMode = () => {
    setIsSignUp((prevMode) => !prevMode);
  };

  const globalStyles = {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700, // ExtraBold
  };
  

  return (
    <Container component="main" maxWidth="xs" sx={{ ...globalStyles, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
          {/* <LockOutlinedIcon /> */}
          <img src="/logo_t.png" alt="Logo" style={{ width: '50%', height: '50%' }}  />
        {/* </Avatar> */}
        <Typography component="h1" variant="h6" sx={{ ...globalStyles, color: '#1E3E62', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {isSignUp ? 'Sign Up' : 'Sign In'}
        </Typography>
        <Box component="form" 
          noValidate 
          sx={{ mt: 1,
          marginTop: 1,
          alignItems: 'center'      
          }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#1E3E62',  // Set the outline color when focused
                },
              },
              '& .MuiInputLabel-root': {
                '&.Mui-focused': {
                  color: '#1E3E62',  // Set the label color when focused
                },
              },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#1E3E62',  // Set the outline color when focused
                },
              },
              '& .MuiInputLabel-root': {
                '&.Mui-focused': {
                  color: '#1E3E62',  // Set the label color when focused
                },
              },
            }}
          />
          {isSignUp && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="current-password"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#1E3E62',  // Set the outline color when focused
                },
              },
              '& .MuiInputLabel-root': {
                '&.Mui-focused': {
                  color: '#1E3E62',  // Set the label color when focused
                },
              },
            }}
          />
          )}
          <br />
          {passwordError && (
            <Typography  sx={{ ...globalStyles, display: 'flex', color: 'red', flexDirection: 'column', alignItems: 'center' }} component="p">
          {passwordError}
            </Typography>
          )}
          {emailError && (
            <Typography  sx={{ ...globalStyles, display: 'flex', color: 'red', flexDirection: 'column', alignItems: 'center' }} component="p" >
          {emailError}
            </Typography>
          )}
                   {/* <span style={{ color: "red" }}>{passwordError}</span>
               
                  <span style={{ color: "red" }}>{emailError}</span> */}
          
          {signInError && (
            <Typography  sx={{ ...globalStyles, display: 'flex', color: 'red', flexDirection: 'column', alignItems: 'center' }} component="p" >
              {signInError}
            </Typography>
          )}
          <Button
            type='button'
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 ,
            backgroundColor: '#212B44 !important',
              '&:hover': {
                backgroundColor: '#313C52 !important', // Force the hover background color
              }, ...globalStyles, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            onClick={handleAuth}
          >
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </Button>
          <Typography component="p" align="center"
             color="secondary"
            style={{ cursor: 'pointer' }} 
            onClick={toggleMode}
            sx={{ ...globalStyles, display: 'flex', flexDirection: 'column', alignItems: 'center',
            color: '#1E3E62'}}         
          >
            {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
          </Typography>
              
         
          <Divider sx={{ margin: '20px 0' ,}} />
          <Button
            variant="contained"
            onClick={signInWithGoogle}
            sx={{ mt:2 , width: '100%' , marginBottom:5,
            backgroundColor: '#212B44 !important',
              '&:hover': {
                backgroundColor: '#313C52 !important', // Force the hover background color
              }, ...globalStyles, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            Sign In with Google
          </Button>
       
        </Box>
      </Box>
    </Container>
  );
};


      
