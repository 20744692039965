import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth } from './components/auth';
import { Home } from './components/home';
// import { AdminHome } from './Admin/home';
import { AdminHome } from './Admin/AdminHome';
import { Dictionary } from './components/dic';
import { Grammar } from './components/grammar';
import { SavedWordsPage } from './components/fav';
import NavigationBar from './components/Navbar/NavigationBar'; // Adjust the path if needed
import Quiz from './components/Quiz';
import { Analytics } from "@vercel/analytics/react";

// import { NavBar } from './components/Home/navBar';
// import {NavigationBar} from './components/Navbar/NavigationBar'
// import {Collections} from './components/collections';
// import { CollectionDetails } from './components/CollectionDetail'; 
import './global.css';
import { About } from './components/about';
function App() {
  useEffect(() => {
    const observer = new ResizeObserver(() => {});
    observer.observe(document.body);

    return () => {
      observer.disconnect();
    };
  }, []);
  
  return (
    <div className="App">
      {/* Wrap the entire application with BrowserRouter */}
      <Router>
      <Analytics />
        {/* nav bar component */}
        {/* <NavBar /> */}
        <NavigationBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/admin" element={<AdminHome />} />
          <Route path="/dictionary" element={<Dictionary />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/grammar" element={<Grammar />}  />
          <Route path="/savedwords" element={<SavedWordsPage/>}  />
          <Route path="/about" element={<About/>}/>
          <Route path="/quiz" element={<Quiz />} />
          {/* <Route path="/collection" element={<Collections />} /> */}
          {/* <Route path="/collections/:collectionName" element={<CollectionDetails />} /> */}
        </Routes>
        {/* footer component */}
      </Router>
    </div>
  );
}

export default App;
