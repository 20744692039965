import { useState, useCallback, useEffect, useRef } from "react";
import './index.css'
import SearchIcon from "@mui/icons-material/Search";

// const useStyles = makeStyles((theme) => ({
//   input: {
//     width: "100%",
//     "&::placeholder": {
//       color: theme.palette.text.secondary, // Default placeholder color
//     },
//     "&:focus::placeholder": {
//       color: theme.palette.primary.main, // Change this to the desired color on focus
//     },
//   },
// }));

export const LiveSearch = ({
  results = [],
  renderItem,
  value,
  onChange,
  onSelect,
  onItemSelected,
}) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const resultContainer = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isInputClicked, setIsInputClicked] = useState(false);


  const handleInputClick = () => {
    setIsInputClicked(true);
    setSelectedItem('');
    
  };

  const handleSelection = (selectedIndex) => {
    const selectedItem = results[selectedIndex];
    setSelectedItem(selectedItem);
      // Call the callback function with the selected item
      onItemSelected && onItemSelected(selectedItem);

    // if (!selectedItem) return resetSearchComplete();
    onSelect && onSelect(selectedItem);
    resetSearchComplete();
  };

  const resetSearchComplete = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleKeyDown = (e) => {
    const { key } = e;
    let nextIndexCount = 0;

    // move down
    if (key === "ArrowDown")
      nextIndexCount = (focusedIndex + 1) % results.length;

    // move up
    if (key === "ArrowUp")
      nextIndexCount = (focusedIndex + results.length - 1) % results.length;

    // hide search results
    if (key === "Escape") {
      resetSearchComplete();
    }

    // select the current item
    if (key === "Enter") {
      e.preventDefault();
      handleSelection(focusedIndex);
    }

    setFocusedIndex(nextIndexCount);
  };

  const handleChange = (e) => {
    setDefaultValue(e.target.value);
    onChange && onChange(e);
  };

  useEffect(() => {
    if (!resultContainer.current) return;

    resultContainer.current.scrollIntoView({
      block: "center",
    });
  }, [focusedIndex]);

  useEffect(() => {
    if (results.length > 0 && !showResults) setShowResults(true);

    if (results.length <= 0) setShowResults(false);
  }, [results]);

  useEffect(() => {
    if (value) setDefaultValue(value);
  }, [value]);

  return (
    <div className="flex pl-10">
      <div
        tabIndex={1}
        onBlur={resetSearchComplete}
        onKeyDown={handleKeyDown}
        className="relative"
      >
        <div className="flex items-center relative">
          <input
            value={defaultValue}
            onChange={handleChange}
            type="text"
           className="w-full sm:w-80 md:w-90 lg:w-96 xl:w-120 px-5 py-5 shadow-md text-sm rounded-full border border-gray-100 focus:border-gray-700 outline-none transition"
           placeholder="Search your word..."
            onClick={handleInputClick}
            style={{
              "text" : "default",
          }}
          />         
          <SearchIcon  style={{
          marginTop: "1px",
          marginLeft: "-40px",
          zIndex: "1",
          cursor: "pointer",
          color: isInputClicked ?  "#000":"#0013" // Set the desired color when input is clicked
        }} />
   
          
          </div>

        
        {/* Search Results Container */}
        {showResults && (
          <div
           style={{ marginTop: "3px"}}
           className=" w-full sm:w-80 md:w-70 lg:w-96 xl:w-120 absolute bg-white rounded-md shadow-md max-h-[60vh] overflow-y-auto border border-gray-100  z-10">
            {results.map((item, index) => (
              <div
                key={index}
                onMouseDown={() => handleSelection(index)}
                ref={index === focusedIndex ? resultContainer : null}
                style={{
                  backgroundColor:
                    index === focusedIndex ? "rgba(0,0,0,0.1)" : "",
                }}
                className="cursor-pointer hover:bg-gray-200 p-2"
              >
                {renderItem(item)}
              </div>
            ))}
          </div>
        )}
        {/* Display the selected item in a card */}
        {selectedItem && (
          <div className="mt-2">
            {/* You can customize the card appearance based on your needs */}
            <div className="bg-gray-100 p-5 rounded-md shadow-md border border-gray-100">
              {renderItem(selectedItem)}
            </div>
          </div>
        )}

       
      </div>
      
    </div>
    
  );
};